var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "white--text",
                    attrs: {
                      color: "primary",
                      loading: false,
                      disabled: _vm.selected.length != 1,
                      text: "",
                      block: ""
                    }
                  },
                  on
                ),
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center justify-start",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-square-edit-outline")]
                      ),
                      _vm._v(" rename ")
                    ],
                    1
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.renameDialog,
        callback: function($$v) {
          _vm.renameDialog = $$v
        },
        expression: "renameDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-center text-uppercase title primary--text"
              },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("insert_drive_file")
                ]),
                _vm._v(" " + _vm._s(_vm.objectType) + " rename ")
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Old " + _vm._s(_vm.objectType) + " name:")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.oldName))]),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("New " + _vm._s(_vm.objectType) + " name:")
                ]),
                _c("v-text-field", {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            "Provide a new " + _vm._s(_vm.objectType) + " name"
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.newFileName,
                    callback: function($$v) {
                      _vm.newFileName = $$v
                    },
                    expression: "newFileName"
                  }
                }),
                _vm.extensionChanged
                  ? _c(
                      "v-alert",
                      { attrs: { prominent: "", type: "warning", text: "" } },
                      [
                        _vm._v(
                          "It seems that you have changed the extension of the file. Click confirm to rename the file with the new extension."
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: { disabled: _vm.renaming, text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.renameDialog = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.renaming, text: "", color: "error" },
                  on: { click: _vm.rename }
                },
                [_vm._v(_vm._s(_vm.buttonName))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }