<template>
    <v-dialog persistent v-model="renameDialog" max-width="600">
        <template v-slot:activator="{ on }">
            <v-btn class="white--text" color="primary" v-on="on" :loading="false" :disabled="selected.length != 1" text block>
                <div style="width:100%" class="d-flex align-center justify-start">
                    <v-icon class="mr-1" small>mdi-square-edit-outline</v-icon>
                    rename
                </div>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center text-uppercase title primary--text">
                    <v-icon class="mr-1">insert_drive_file</v-icon>
                    {{ objectType }} rename
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <div class="mt-3">
                    <span class="font-weight-bold">Old {{ objectType }} name:</span>
                    <p>{{ oldName }}</p>
                    <span class="font-weight-bold">New {{ objectType }} name:</span>
                    <v-text-field v-model="newFileName">
                        <template v-slot:label>Provide a new {{ objectType }} name</template>
                    </v-text-field>
                    <v-alert prominent type="warning" text v-if="extensionChanged"
                        >It seems that you have changed the extension of the file. Click confirm to rename the file with the new extension.</v-alert
                    >
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="renaming" text color="primary" class="mr-1" @click="renameDialog = false">Cancel</v-btn>
                <v-btn :loading="renaming" text color="error" @click="rename">{{ buttonName }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'RenameButton',
    data: function() {
        return {
            renameDialog: false,
            newFileName: '',
            successMessage: '',
            renaming: false
        }
    },
    props: {
        selected: Array,
        objectType: String,
        dispatchFunction: Function
    },
    computed: {
        oldName: function() {
            if (this.$props.selected.length) {
                return this.$props.selected[0].short_id
            } else {
                return ''
            }
        },
        extensionChanged: function() {
            if (this.objectType === 'file' && this.oldName.includes('.') && this.oldName.split('.').pop() !== this.newFileName.split('.').pop()) {
                return true
            }
            return false
        },
        buttonName: function() {
            if (this.objectType === 'file') {
                if (this.oldName.split('.').pop() === this.newFileName.split('.').pop()) {
                    return 'rename'
                } else {
                    return 'confirm'
                }
            } else {
                return 'rename'
            }
        }
    },
    methods: {
        rename() {
            this.$data.renaming = true
            const patchBody = {
                short_id: this.$data.newFileName
            }
            var apiURL
            if (this.$props.objectType === 'file') {
                this.$data.successMessage = 'File renamed successfully!'
                apiURL = `files/${this.$props.selected[0].fid}`
            } else if (this.$props.objectType === 'table') {
                this.$data.successMessage = 'Table renamed successfully!'
                apiURL = `tables/${this.$props.selected[0].tid}`
            } else if (this.$props.objectType === 'column') {
                this.$data.successMessage = 'Column renamed successfully!'
                apiURL = `columns/${this.$props.selected[0].cid}`
            }
            this.$axios
                .patch(apiURL, patchBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: this.$data.successMessage,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.dispatchFunction()
                })
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to rename the ${this.objectType}.`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$emit('finished', { value: true })
                    this.$data.renaming = false
                    this.$data.renameDialog = false
                })
        }
    },
    mounted() {
        this.$data.newFileName = this.oldName
    }
}
</script>
